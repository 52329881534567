<template>
  <div>
    <div class="h-full white-section !p-0">
      <div v-if="title" class="absolute top-0 left-[50%] bg-elf-green-500 text-white py-0.5 px-5 rounded w-max" style="transform: translateY(-50%) translateX(-50%)">
        <h2 class="!text-sm font-medium">{{ title }}</h2>
      </div>
      <div class="h-full" :class="{ 'p-4': !disablePadding, 'overflow-auto': !outbound }">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
defineProps([
  "title",
  "disablePadding",
  "outbound",
]);
</script>


<script lang="ts">
export default {
  name: "BentoBlock"
};
</script>

